import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Markdown from "../../Markdown/Markdown";
import Button from "../../Button/Button";

import "./styles.scss";
import LazyImg from "../../LazyImg/LazyImg";

const HubPosts = ({
  primary: { title, description, load_more_button_text },
  posts = [],
  lang
}) => {
  const [maxPosts, setMaxPosts] = useState(9);

  posts = posts.sort((a, b) => {
    return new Date(b.node.data.date) - new Date(a.node.data.date);
  });

  return posts && posts.length > 0 ? (
    <div className="HubPosts">
      <div className="container">
        <div className="container-md">
          {title && title.text && <h2>{title.text}</h2>}
          {description && description.html && (
            <Markdown input={description.html} />
          )}
          <div className="HubPosts-wrapper">
            {posts.map((post, index) => {
              if (index + 1 > maxPosts) return null;

              const { title, hub_detail_thumbnail, date } = post.node.data;

              const LANGS_MAP = {
                en: "en-US",
                es: "es-ES"
              };
              const d = new Date(date).toLocaleDateString(LANGS_MAP[lang], {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
              });

              return (
                <div className="HubPosts-talk" key={hub_detail_thumbnail.url}>
                  <div>
                    <LazyImg
                      className="HubPosts-talk-image"
                      src={hub_detail_thumbnail.url + '&w=600'}
                      spaceHolderFix={100}
                    />
                    <h3 className="HubPosts-talk-name">{title.text}</h3>
                    <p className="HubPosts-talk-date">{d}</p>

                    <Link
                      className="HubPosts-talk-link"
                      to={`/${lang}/hub/${post.node.uid}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {posts.length > maxPosts && (
            <Button
              className="HubPosts-load-more"
              onClick={() => {
                setMaxPosts(maxPosts + 6);
              }}
            >
              {load_more_button_text}
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default HubPosts;
