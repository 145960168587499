import React, { useState, useEffect, useRef } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";

import Portal from "../Portal/Portal";
import Button from "../Button/Button";

import { isNode } from "../../utils";

import "./styles.scss";

const Video = ({ src, withButton = true, withText }) => {
  const videoPortalWraper = useRef(null);
  const [play, setPlay] = useState(!withButton);

  useEffect(() => {
    if (!isNode) {
      const keyUpListener = event => {
        const code = event.which || event.keyCode;
        if (code === 27) {
          clearAllBodyScrollLocks();
          setPlay(false);
        }
      };
      window.addEventListener("keyup", keyUpListener);
      return () => {
        clearAllBodyScrollLocks();
        window.removeEventListener("keyup", keyUpListener);
      };
    }
  });

  return (
    <div className="Video">
      {withButton && (
        <div className="Video-button">
          <Button
            className="Video-play"
            onClick={() => {
              disableBodyScroll(videoPortalWraper.current);
              setPlay(true);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 0C114.617 0 0 114.615 0 256s114.617 256 256 256 256-114.615 256-256S397.383 0 256 0zm88.48 269.57l-128 80a16.008 16.008 0 0 1-16.238.422A15.994 15.994 0 0 1 192 336V176c0-5.82 3.156-11.172 8.242-13.992a15.957 15.957 0 0 1 16.238.422l128 80c4.676 2.93 7.52 8.055 7.52 13.57s-2.844 10.641-7.52 13.57z" />
            </svg>
          </Button>
          {withText && <p>{withText}</p>}
        </div>
      )}
      <Portal rootId="Video-portal">
        {play && (
          <div className="Video-portal-in" ref={videoPortalWraper}>
            <video
              className={"Video-portal-video"}
              muted={!withButton}
              loop
              autoPlay={true}
              playsInline
              preload="auto"
            >
              <source src={src} type="video/mp4" />
            </video>

            <Button
              className="Video-close"
              onClick={() => {
                clearAllBodyScrollLocks();
                setPlay(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15.642 15.642"
              >
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z"
                />
              </svg>
            </Button>
          </div>
        )}
      </Portal>
    </div>
  );
};

export default Video;
