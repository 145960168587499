import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Markdown from "../../Markdown/Markdown";

import "./styles.scss";
import LazyImg from "../../LazyImg/LazyImg";

const FutureTalks = ({ primary: { title, description }, items = [], lang }) => {
  items = items.sort((a, b) => {
    return (
      new Date(
        a.future_talk.document ? a.future_talk.document.data.date : null
      ) -
      new Date(b.future_talk.document ? b.future_talk.document.data.date : null)
    );
  });

  return items && items.length > 0 ? (
    <div className="FutureTalks">
      <div className="container">
        <div className="container-md">
          {title && title.text && <h2>{title.text}</h2>}
          {description && description.html && (
            <Markdown input={description.html} />
          )}
          <div className="FutureTalks-wrapper">
            {items.map(({ future_talk }, index) => {
              if (index > 2 || !future_talk.document) {
                return null;
              }

              const {
                title,
                hub_detail_thumbnail,
                date
              } = future_talk.document.data;

              const LANGS_MAP = {
                en: "en-US",
                es: "es-ES"
              };
              const d = new Date(date).toLocaleDateString(LANGS_MAP[lang], {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
              });

              return (
                <div className="FutureTalks-talk" key={title.text + index}>
                  <div>
                    <LazyImg
                      className="FutureTalks-talk-image"
                      src={hub_detail_thumbnail.url + '&w=600'}
                      spaceHolderFix={100}
                    />
                    <h3 className="FutureTalks-talk-name">{title.text}</h3>
                    <p className="FutureTalks-talk-date">{d}</p>

                    <Link
                      className="FutureTalks-talk-link"
                      to={`/${lang}/hub/${future_talk.document.uid}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default FutureTalks;
