import * as React from "react";
import { useEffect } from "react";
import * as ReactDOM from "react-dom";

import { isNode } from "../../utils";

/*
 * This element allows you to render children in a different part of the DOM
 */
const Portal = props => {
  const element = !isNode ? document.createElement("div") : null;
  const rootId = props.rootId || "portal";
  const modalRoot = !isNode ? document.getElementById(rootId) : null;

  useEffect(() => {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    if (modalRoot) {
      modalRoot.appendChild(element);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(element);
      }
    };
  });

  return !isNode ? (
    ReactDOM.createPortal(props.children, element)
  ) : (
    <>{props.children}</>
  );
};

export default Portal;
