import React from "react";
import classnames from "classnames";

import LazyImg from "../../LazyImg/LazyImg";

import "./styles.scss";

const LogosList = ({ primary: { title }, items = [] }) => {
  return (
    <div className={classnames("LogosList")}>
      <div className="LogosList-inner">
        <div className="container">
          <div className="container-md">
            {title && title.text && <h2>{title.text}</h2>}
            <div className="LogosList-wrapper">
              {items.map(item => {
                const { height, width } = item.logo.dimensions;
                return (
                  <div
                    key={item.logo.url}
                    className={`LogosList-logo ${
                      (height / width) * 100 > 45 ? "sm" : ""
                    }`}
                  >
                    <a
                      href={item.logo_link_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyImg src={item.logo.url} alt={item.logo.alt} />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogosList;
