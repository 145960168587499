import React from "react";

import LazyImg from "../../LazyImg/LazyImg";

import "./styles.scss";

const ImageBlock = ({ primary: { image } }) => {
  return (
    <div className="ImageBlock">
      <LazyImg
        className="ImageBlock-image"
        src={image.url + '&w=2000'}
        alt={image.alt}
        isBackground
      />
    </div>
  );
};

export default ImageBlock;
