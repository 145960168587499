import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

import "./styles.scss";

import LazyImg from "../../LazyImg/LazyImg";

const BigGallery = ({ items = [] }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const currentImage = items[photoIndex];
  const nextImage = items[(photoIndex + 1) % items.length];
  const prevImage = items[(photoIndex + items.length - 1) % items.length];

  return (
    <div className="BigGallery">
      <div className="container">
        <Gallery
          photos={items.map(item => ({
            src: item.image.url,
            alt: item.image.alt,
            width: item.image.dimensions.width,
            height: item.image.dimensions.height
          }))}
          renderImage={photoOpts => {
            const { photo, margin, index } = photoOpts;
            return (
              <LazyImg
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
                className="BigGallery-image"
                key={photo.src}
                src={photo.src}
                alt={photo.alt}
                style={{
                  width: photo.width + "px",
                  height: photo.height + "px",
                  margin: margin + "px"
                }}
                spaceHolderFix={(photo.height / photo.width) * 100}
              />
            );
          }}
        />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={currentImage ? currentImage.image.url : null}
          nextSrc={nextImage ? nextImage.image.url : null}
          prevSrc={prevImage ? prevImage.image.url : null}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % items.length)
          }
        />
      )}
    </div>
  );
};

export default BigGallery;
