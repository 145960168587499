import React from "react";

import plantLeft from "../../../images/plant-left.svg";
import plantsSmall from "../../../images/plants-small.svg";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const PricingTables = ({ primary: { title, description }, items = [] }) => (
  <div className="PricingTables">
    <div className="PricingTables-inner">
      <div className="container">
        <div className="container-md">
          <div className="PricingTables-top">
            <LazyImg
              className="PricingTables-plant-left"
              src={plantLeft}
              alt={"Plant left"}
            />
            <LazyImg
              className="PricingTables-plants-small"
              src={plantsSmall}
              alt={"Plants left"}
            />

            {title && <h1>{title.text}</h1>}
            {description && <Markdown input={description.html} styleLink />}
          </div>
          <div className="PricingTables-types">
            {items.map(
              ({
                pricing_type_title: titleT,
                pricing_type_description: descriptionT,
                pricing_type_icon: icon,
                pricing_type_pricing: price,
                pricing_type_link_url: link_url,
                pricing_type_link_text: link_text
              }) => {
                const chars =
                  (descriptionT && descriptionT.html
                    ? descriptionT.html.length
                    : 0) + (price && price.html ? price.html.length : 0);
                return (
                  <div
                    className={`PricingTables-type ${
                      chars > 190 ? "long" : ""
                    }`}
                    key={titleT.text}
                  >
                    <div>
                      <div className="PricingTables-type-image">
                        <LazyImg src={icon.url} alt={icon.alt} />
                      </div>
                      {titleT && (
                        <h3 className="PricingTables-type-title">
                          {titleT.text}
                        </h3>
                      )}
                      {descriptionT && descriptionT.html !== "<p></p>" && (
                        <Markdown
                          className="PricingTables-type-description"
                          input={descriptionT.html}
                        />
                      )}

                      {price && price.html && (
                        <Markdown
                          className="PricingTables-type-price"
                          input={price.html}
                        />
                      )}
                      {link_text && link_url && (
                        <a
                          href={link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="PricingTables-type-link Link-generic"
                        >
                          {link_text}
                        </a>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PricingTables;
