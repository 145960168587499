import React from "react";
import classnames from "classnames";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const AddressAndMap = ({
  primary: {
    title,
    label,
    address,
    map_image,
    see_in_maps_text = "Ver en Google Maps",
    map_url = "https://www.google.com/maps/place/Ehub!+Coworking+Plaza+del+Gas/@43.2666967,-2.9227149,506a,35y,180h,39.32t/data=!3m1!1e3!4m5!3m4!1s0xd4e4fc6040331c7:0xfc1ba88ecbaca476!8m2!3d43.2636145!4d-2.9222647"
  },
  items = []
}) => {
  return (
    <div className="AddressAndMap">
      <div className="AddressAndMap-inner">
        <div className="container">
          <div className="container-md">
            <div className="AddressAndMap-wrapper">
              <div className="AddressAndMap-wrapper-left">
                <h2 className="AddressAndMap-title">
                  <span className="AddressAndMap-label line-label">
                    {label}
                  </span>
                  {title.text}
                </h2>
                <Markdown input={address.html} />
              </div>
              {map_image && map_image.url && (
                <div className="AddressAndMap-wrapper-right">
                  <a
                    href={map_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Ver EHUB en Google Maps"
                  >
                    <LazyImg
                      src={map_image.url}
                      alt={map_image.alt}
                      spaceHolderFix={
                        (map_image.dimensions.height /
                          map_image.dimensions.width) *
                        100
                      }
                    />

                    <div className="AddressAndMap-location-icon-wrapper">
                      <svg
                        className="AddressAndMap-location-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 486.3 486.3"
                      >
                        <path
                          d="M243.15 0v104.4c44.11 0 80 35.88 80 80 0 44.11-35.89 80-80 80v221.9l146.43-184.1c26.29-33.25 40.19-73.21 40.19-115.58C429.77 83.72 346.05 0 243.15 0z"
                          fill="#f44336"
                        />
                        <path
                          d="M323.15 184.4c0-44.12-35.89-80-80-80v160c44.11 0 80-35.89 80-80z"
                          fill="#d8d7da"
                        />
                        <path
                          d="M163.15 184.4c0-44.12 35.89-80 80-80V0C140.25 0 56.53 83.72 56.53 186.62c0 42.37 13.9 82.33 40.23 115.62L243.15 486.3V264.4c-44.11 0-80-35.89-80-80z"
                          fill="#f44336"
                        />
                        <path
                          d="M163.15 184.4c0 44.11 35.89 80 80 80v-160c-44.11 0-80 35.88-80 80z"
                          fill="#fff"
                        />
                      </svg>
                      <span>{see_in_maps_text}</span>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressAndMap;
