import * as React from "react";

import {
  Hero,
  SmallGallery,
  ImageContent,
  ImageBlock,
  CallToAction,
  ColumnsImageText,
  LogosList,
  Content,
  BigGallery,
  Testimonials,
  AddressAndMap,
  PricingTables,
  Services,
  FutureTalks,
  HubPosts,
  Banner
} from "./";

const COMPONENTS = {
  /* 
    Name_component: NameComponent
  */
  Hero: Hero, // Prismic name: Hero
  Small_gallery: SmallGallery, // Prismic name: Hero
  Image___content: ImageContent, // Prismic name: Image + Content
  Image_block: ImageBlock, // Prismic name: Image Block
  Call_to_action: CallToAction, // Prismic name: Call To Action
  Columns_with_image_and_text: ColumnsImageText, // Prismic name: Columns with Image and Text
  Logos_list: LogosList, // Prismic name: Columns with image and text
  Content: Content, // Prismic name: Content
  Big_gallery: BigGallery, // Prismic name: ContentBigGallery
  Testimonials: Testimonials, // Prismic name: Testimonials
  Address_and_map: AddressAndMap, // Prismic name: Address and Map
  Pricing_tables: PricingTables, // Prismic name: Pricing Tables
  Services: Services, // Prismic name: Services
  Future_talks: FutureTalks, // Prismic name: Future Talks
  Hub_posts: HubPosts, // Prismic name: Hub Posts,
  Banner: Banner // Prismic name: Banner
};

const ContentBlock = props => {
  const { tag } = props;

  if (!tag) return null;

  const TagName = COMPONENTS[tag];

  if (!TagName) return null;

  return <TagName {...props} />;
};

export default ContentBlock;
