import React from "react";

import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const Content = ({ primary: { content_title, content } }) => {
  return (
    <div className="Content">
      <div className="container">
        <div className="container-md">
          {content_title && content_title.text && <h2>{content_title.text}</h2>}
          {content && content.html && <Markdown input={content.html} />}
        </div>
      </div>
    </div>
  );
};

export default Content;
