import React from "react";
import classnames from "classnames";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const ColumnsImageText = ({ items = [] }) => {
  return (
    <div className="ColumnsImageText">
      <div className="ColumnsImageText-inner">
        <div className="container">
          <div className="container-md">
            <div className="ColumnsImageText-wrapper">
              {items.map(item => (
                <div key={item.title.text} className="ColumnsImageText-column">
                  <LazyImg
                    isBackground
                    src={item.image.url + '&w=600'}
                    alt={item.image.alt}
                    spaceHolderFix={135}
                  />
                  {item.title.text && <h4>{item.title.text}</h4>}
                  {item.description.html && (
                    <Markdown input={item.description.html} styleLink />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnsImageText;
