import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const ImageContent = ({
  primary: { label, title, description, link_text, link_url, image_side },
  items = []
}) => {
  return (
    <div className={classnames("ImageContent", image_side.toLowerCase())}>
      <div className="ImageContent-inner">
        <div className="container">
          <div className="container-md">
            <div className="ImageContent-wrapper">
              <div className="ImageContent-content">
                <h2>
                  <span className="ImageContent-label line-label">{label}</span>
                  {title.text}
                </h2>
                <Markdown input={description.html} styleLink />
                {link_text && link_url && (
                  <Link
                    to={link_url}
                    className="ImageContent-link Link-generic"
                  >
                    {link_text}
                  </Link>
                )}
              </div>

              <div className="ImageContent-images">
                {items.map(({ side_image: image }, index) => (
                  <LazyImg
                    key={image.url + index}
                    className="ImageContent-image"
                    src={image.url + '&w=450'}
                    alt={image.alt}
                    spaceHolderFix={
                      (image.dimensions.height / image.dimensions.width) * 100
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContent;
