import React from "react";
import classnames from "classnames";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";
import Video from "../../Video/Video";

import plantRight from "../../../images/plant-right.svg";
import plantLeft from "../../../images/plant-left.svg";
import plantsSmall from "../../../images/plants-small.svg";

import "./styles.scss";

const Hero = ({
  primary: {
    hero_label,
    hero_title,
    hero_description,
    hero_image,
    hero_video,
    hero_video_text
  }
}) => {
  return (
    <div className={classnames("Hero")}>
      <LazyImg
        className="Hero-plant-right"
        src={plantRight}
        alt={"Plant right"}
      />
      <LazyImg className="Hero-plant-left" src={plantLeft} alt={"Plant left"} />
      <LazyImg
        className="Hero-plants-small"
        src={plantsSmall}
        alt={"Plants left"}
      />

      <div className="Hero-inner">
        <div className="container">
          <div className="container-md">
            <div className="Hero-wrapper">
              <div className="Hero-content">
                <h1 className="Hero-title">
                  <span className="Hero-title-label line-label">
                    {hero_label}
                  </span>
                  {hero_title.text}
                </h1>
                <Markdown input={hero_description.html} />
                {hero_video && hero_video.url && (
                  <Video
                    src={hero_video.url}
                    withButton={true}
                    withText={hero_video_text}
                  />
                )}
              </div>
              {hero_image.url && (
                <div className="Hero-image">
                  <LazyImg
                    src={hero_image.url}
                    alt={hero_image.alt}
                    spaceHolderFix={
                      (hero_image.dimensions.height /
                        hero_image.dimensions.width) *
                      100
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
