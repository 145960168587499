import React from "react";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const Testimonials = ({
  primary: { title, label, description },
  items = []
}) => {
  return (
    <div className="Testimonials">
      <div className="Testimonials-inner">
        <div className="container">
          <div className="container-md">
            <div className="Testimonials-wrapper">
              <div className="Testimonials-title-desc">
                {title && title.text && (
                  <h2>
                    <span className="Testimonials-label line-label">
                      {label}
                    </span>
                    {title.text}
                  </h2>
                )}
                {description &&
                  description.html &&
                  description.html !== "<p></p>" && (
                    <Markdown input={description.html} />
                  )}
              </div>

              <div className="Testimonials-list">
                <ul>
                  {items.map((item, index) =>
                    index > 2 ? null : (
                      <li
                        key={item.user_name}
                        className="Testimonials-testimonial"
                      >
                        <div className="Testimonials-testimonial-avatar">
                          <LazyImg src={item.user_avatar.url} />
                        </div>
                        <div className="Testimonials-testimonial-content">
                          <i>
                            <svg
                              width="26"
                              height="22"
                              viewBox="0 0 26 22"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.366 11.616h3.74v9.973H.133v-7.547c0-8.243 3.324-12.657 9.973-13.24V4.54c-2.493.696-3.74 2.695-3.74 5.997v1.078zm14.96 0h3.739v9.973h-9.973v-7.547c0-8.243 3.324-12.657 9.973-13.24V4.54c-2.493.696-3.74 2.695-3.74 5.997v1.078z"
                                fill="#2d3440"
                                fillRule="nonzero"
                              />
                            </svg>
                          </i>
                          <Markdown input={item.user_comment.html} />
                          <b className="Testimonials-testimonial-content-name">
                            {item.user_name}
                          </b>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
