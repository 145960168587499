import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import LazyImg from "../../LazyImg/LazyImg";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const SmallGallery = ({
  primary: {
    gallery_title,
    gallery_description,
    see_gallery_link_text,
    see_gallery_link_url
  },
  items = []
}) => {
  return (
    <div className={classnames("SmallGallery")}>
      <div className="SmallGallery-inner">
        <div className="container">
          <div className="container-md">
            <div className="SmallGallery-wrapper">
              <div className="SmallGallery-content">
                <h2>{gallery_title.text}</h2>
                <Markdown input={gallery_description.html} />
                {see_gallery_link_text && see_gallery_link_url && (
                  <Link
                    to={see_gallery_link_url}
                    className="SmallGallery-link Link-generic"
                  >
                    {see_gallery_link_text}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="SmallGallery-gallery">
          {items.map(({ gallery_image: image }, index) => (
            <LazyImg
              key={image.url + index}
              className="SmallGallery-image"
              src={image.url}
              alt={image.alt}
              isBackground
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SmallGallery;
