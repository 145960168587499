import React from "react";

import Button from "../../Button/Button";
import Markdown from "../../Markdown/Markdown";

import "./styles.scss";

const CallToAction = ({
  primary: { title, description, link_url, link_text, background_color }
}) => {
  return (
    <div className={`CallToAction ${background_color.toLowerCase()}`}>
      <div className="container">
        <div className="container-md">
          <div className="CallToAction-wrapper">
            <div>
              {title.text && <h3>{title.text}</h3>}
              {description &&
                description.html &&
                description.html !== "<p></p>" && (
                  <Markdown input={description.html} />
                )}
            </div>
            <Button
              className={`CallToAction-link ${
                background_color === "Blue" || background_color === "Grey"
                  ? "secondary"
                  : ""
              } ${background_color === "Blue" ? "no-hover-white" : ""} bg`}
              to={link_url}
            >
              {link_text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
