import React from "react";

import Button from "../../Button/Button";
import Markdown from "../../Markdown/Markdown";
import LazyImg from "../../LazyImg/LazyImg";
import PLANT_LEFT from "../../../images/plant-left.svg";
import PLANT_RIGHT from "../../../images/plants-small.svg";

import "./styles.scss";

const Banner = ({
  primary: { title, description, link_url, link_text, image, side = "right" },
}) => (
  <div className={`Banner Banner--${side}`}>
    <div className="container">
      <div className="container-md">
        <div className="Banner__in">
          <div className="Banner__in-wrapper">
            {title.text && <h3>{title.text}</h3>}
            {description &&
              description.html &&
              description.html !== "<p></p>" && (
                <Markdown input={description.html} />
              )}

            <Button className="Banner__link secondary" to={link_url}>
              {link_text}
            </Button>

            <LazyImg
              className="Banner__plant-left--top"
              src={PLANT_RIGHT}
              alt={"Plant right"}
            />

            <LazyImg
              className="Banner__plant-left--bottom"
              src={PLANT_LEFT}
              alt={"Plant left"}
            />
          </div>

          {image?.url && (
            <LazyImg
              className="Banner__image"
              src={image.url + "&w=800"}
              alt={image.alt}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
