import React from "react";
import classnames from "classnames";

import plantLeft from "../../../images/plant-left.svg";
import plantsSmall from "../../../images/plants-small.svg";

import Markdown from "../../Markdown/Markdown";
import LazyImg from "../../LazyImg/LazyImg";

import "./styles.scss";

const Services = ({ primary: { title, description }, items = [] }) => {
  return (
    <div className={classnames("Services")}>
      <div className="Services-inner">
        <div className="container">
          <div className="container-md">
            <div className="Services-top">
              <LazyImg
                className="Services-plant-left"
                src={plantLeft}
                alt={"Plant left"}
              />
              <LazyImg
                className="Services-plants-small"
                src={plantsSmall}
                alt={"Plants left"}
              />

              {title && <h1>{title.text}</h1>}
              {description && <Markdown input={description.html} styleLink />}
            </div>
            <div className="Services-wrapper">
              {items.map(item => {
                return (
                  <div key={item.service_name.text} className={`Services-item`}>
                    <div>
                      {item.service_icon && item.service_icon.url && (
                        <LazyImg src={item.service_icon.url} />
                      )}
                      {item.service_name && item.service_name.text && (
                        <h4>{item.service_name.text}</h4>
                      )}
                      {item.service_description &&
                        item.service_description.html !== "<p> </p>" &&
                        item.service_description.html !== "<p></p>" && (
                          <Markdown input={item.service_description.html} />
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
